<template>
  <div>
    <h2 class="content-heading pt-0"><i class="fa fa-fw fa-info text-muted mr-1"></i> User Details</h2>
    <div class="row push">
      <div class="col-lg-4">
        <p class="text-muted">An email will be sent to the user to finalise their account creation.</p>
      </div>
      <div class="col-lg-8 col-xl-5">
        <FormGroup id="name" v-model="form.name" :disabled="disabled" :error="error['name']" label="Name" type="text" @input="$emit('input', form)" />
        <FormGroup
          id="email"
          v-model="form.email"
          :disabled="disabled"
          :error="error['email']"
          label="Email"
          type="text"
          @input="$emit('input', form)"
        />
        <FormGroup
          id="roleId"
          v-model="form.roleId"
          :disabled="disabled"
          :error="error['roleId']"
          label="Role"
          type="select"
          :options="roles.map(role => ({ label: role.description, value: role.id }))"
          @input="$emit('input', form)"
        />
        <FormGroup
          v-if="entityRoleIds.includes(form.roleId)"
          id="entityId"
          v-model="form.entityId"
          :disabled="disabled"
          :error="error['entityId']"
          label="Entity"
          type="select2"
          :config="{ allowClear: true }"
          :options="
            sortedEntities.map(entity => ({
              label: `${entity.legalName} ${entity.ultimateParentEntityId === entity._id ? '[Top Level]' : ''}`,
              value: entity._id
            }))
          "
          @input="$emit('input', form)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

import FormGroup from '../FormGroup';

export default {
  name: 'UserForm',
  components: {
    FormGroup
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    error: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openMenu: '',
      openSubMenu: '',
      form: {},
      entityRoleIds: []
    };
  },
  computed: {
    ...mapGetters({
      entities: 'entity/entities',
      roles: 'company/roles'
    }),
    sortedEntities() {
      const topLevelEntities = this.entities.filter(entity => entity.ultimateParentEntityId === entity._id);
      const allOtherEntities = this.entities.filter(entity => entity.ultimateParentEntityId !== entity._id);

      return [...topLevelEntities, ...allOtherEntities];
    }
  },
  async mounted() {
    await this.getRoles();

    this.entityRoleIds = this.roles.filter(role => role.name.startsWith('entity')).map(role => role.id);

    this.form = {
      ...this.value
    };

    this.listEntities({ data: { params: { companyId: this.$route.params.id, $sort: 'legalName:1' } } });
  },
  methods: {
    ...mapActions({
      listEntities: 'entity/list',
      getRoles: 'company/getRoles'
    })
  },
  watch: {
    value(val) {
      if (val.userSub !== this.form.userSub) {
        this.form = {
          name: val.name,
          email: val.email,
          roleId: val.roleId,
          entityId: val.entityId
        };
      }
    },
    form(form) {
      if (this.form.userSub === form.userSub) {
        this.$emit('input', this.form);
      }
    }
  }
};
</script>
