var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "row push"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-lg-8 col-xl-5"
  }, [_c('FormGroup', {
    attrs: {
      "id": "name",
      "disabled": _vm.disabled,
      "error": _vm.error['name'],
      "label": "Name",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.name,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "email",
      "disabled": _vm.disabled,
      "error": _vm.error['email'],
      "label": "Email",
      "type": "text"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.email,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "email", $$v);
      },
      expression: "form.email"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "roleId",
      "disabled": _vm.disabled,
      "error": _vm.error['roleId'],
      "label": "Role",
      "type": "select",
      "options": _vm.roles.map(function (role) {
        return {
          label: role.description,
          value: role.id
        };
      })
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.roleId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "roleId", $$v);
      },
      expression: "form.roleId"
    }
  }), _vm.entityRoleIds.includes(_vm.form.roleId) ? _c('FormGroup', {
    attrs: {
      "id": "entityId",
      "disabled": _vm.disabled,
      "error": _vm.error['entityId'],
      "label": "Entity",
      "type": "select2",
      "config": {
        allowClear: true
      },
      "options": _vm.sortedEntities.map(function (entity) {
        return {
          label: "".concat(entity.legalName, " ").concat(entity.ultimateParentEntityId === entity._id ? '[Top Level]' : ''),
          value: entity._id
        };
      })
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', _vm.form);
      }
    },
    model: {
      value: _vm.form.entityId,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "entityId", $$v);
      },
      expression: "form.entityId"
    }
  }) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h2', {
    staticClass: "content-heading pt-0"
  }, [_c('i', {
    staticClass: "fa fa-fw fa-info text-muted mr-1"
  }), _vm._v(" User Details")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-4"
  }, [_c('p', {
    staticClass: "text-muted"
  }, [_vm._v("An email will be sent to the user to finalise their account creation.")])]);
}]

export { render, staticRenderFns }